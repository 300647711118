import React, { CSSProperties, useCallback, useEffect, useId, useMemo, useRef, useState } from 'react'
import { rgba } from 'polished'
import styled from 'styled-components'
import { FormElement, Input } from 'components/atoms'
import { colors } from 'theme'
import useClickAway from 'hooks/useClickAway'

type Props<T extends string> = {
  name: T;
  label: string;
  error?: string;
  placeholder?: string;
  options: OptionType[];
  selectedOption?: OptionType;
  disabled?: boolean;
  inputStyle?: CSSProperties;
  dropdownIndicatorStyle?: CSSProperties;
  isRequired?: boolean;
  onChange: (value: string, field: T) => void;
}

type OptionType = {
  label: string;
  value: string;
}

const FormSelect = <T extends string>({
  name,
  label,
  error,
  options,
  placeholder,
  selectedOption,
  disabled = false,
  inputStyle,
  dropdownIndicatorStyle,
  isRequired = false,
  onChange,
}: Props<T>) => {
  const id = useId()
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const labelId = useId()

  const selectedOptionIndex = useMemo(() => {
    return options.findIndex((option) => option.value === selectedOption?.value)
  }, [options, selectedOption])

  const focusOption = useCallback((index: number) => {
    const option = document.getElementById(`option-${index}`)

    if (option instanceof HTMLElement) {
      option.focus()
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      if (selectedOptionIndex !== -1) {
        focusOption(selectedOptionIndex)

        return
      }

      focusOption(0)
    }
  }, [isOpen, selectedOptionIndex, focusOption])

  const handleClick = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    if (disabled || options.length === 0) {
      return
    }

    e.preventDefault()
    e.stopPropagation()
    setIsOpen(!isOpen)
  }, [disabled, options, isOpen])

  const handleChange = useCallback((value: string) => {
    setIsOpen(false)
    onChange(value, name)
  }, [onChange, name])

  const handleInputKeyDown = (e: React.KeyboardEvent) => {

    switch (e.key) {
    case 'ArrowDown': {
      e.preventDefault()
      setIsOpen(true)

      break
    }

    default:
      break
    }
  }

  const handleOptionKeyDown = useCallback((e: React.KeyboardEvent, value: string) => {
    switch (e.key) {
    case 'Enter': {
      e.preventDefault()

      onChange(value, name)

      break
    }

    case 'ArrowDown':
    case 'Tab': {
      e.preventDefault()

      const next = document?.activeElement?.nextSibling

      if (next instanceof HTMLElement) {
        next.focus()
      }

      break
    }

    case 'ArrowUp': {
      e.preventDefault()

      const previous = document?.activeElement?.previousSibling

      if (previous instanceof HTMLElement) {
        previous.focus()
      }

      break
    }

    case 'Escape': {
      e.preventDefault()
      setIsOpen(false)

      break
    }

    default:
      break
    }
  }, [onChange, name])

  useClickAway(ref, () => setIsOpen(false))

  return (
    <FormElement
      error={ error }
      isRequired={ isRequired }
      label={ label }
      labelId={ labelId }
      name={ id }
    >
      <Wrapper ref={ ref }>
        <Input
          aria-labelledby={ labelId }
          disabled={ disabled || options.length === 0 }
          dropdownIndicatorStyle={ dropdownIndicatorStyle }
          error={ error }
          id={ id }
          isOpen={ isOpen }
          name={ name }
          placeholder={ placeholder }
          style={ inputStyle }
          type='select'
          value={ selectedOption?.label ?? '' }
          onClick={ handleClick }
          onKeyDown={ handleInputKeyDown }
        />
        {
          options.length > 0 && (
            <OptionList
              $isOpen={ isOpen }
              aria-expanded={ isOpen }
              role='listbox'
            >
              { options.map(({ value, label }, index) =>
                <Option
                  $isSelected={ selectedOption?.value === value }
                  aria-selected={ selectedOption?.value === value }
                  id={ `option-${index}` }
                  key={ value }
                  role='option'
                  tabIndex={ 0 }
                  onClick={ () => handleChange(value) }
                  onKeyDown={ (e) => handleOptionKeyDown(e, value) }
                >
                  { label }
                </Option>,
                ) }
            </OptionList>
          )
        }
      </Wrapper>
    </FormElement>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const OptionList = styled.ul<{ $isOpen: boolean }>`
  position: absolute;
  z-index: 2;
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  min-width: 100%;
  max-height: 250px;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  color: ${colors.midnightBlue};
  list-style-type: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGray};
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 18%);
`

const lowAlphaGreen = rgba(colors.lightGreen, 0.2)

const Option = styled.li<{ $isSelected?: boolean }>`
  padding: 0.75rem;
  cursor: pointer;

  ${({ $isSelected }) => $isSelected && `
    background-color: ${colors.blueLagoon};
    color: ${colors.white};
  `}

  &:hover {
    color: ${colors.midnightBlue};
    background-color: ${lowAlphaGreen};
  }

  &:focus {
    color: ${({ $isSelected }) => $isSelected ? colors.white : colors.midnightBlue};
    background-color: ${({ $isSelected }) => $isSelected ? colors.blueLagoon : lowAlphaGreen};
    outline: none;
  }
`

export default FormSelect
