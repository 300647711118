import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'components/atoms'
import { colors } from 'theme'
import AverageScore from './statistics/AverageScore'
import TotalScore from './statistics/TotalScore'
import Badges from './statistics/Badges'
import { SCORES } from 'graphql/queries/statistics'

const Statistics = ({ members }) => {
  const [activeItem, setActiveItem] = useState('averageScore')
  let today = new Date()
  const monthYears = []
  const formatedMonthYears = []
  const dateFormat = {
    year: 'numeric',
    month: 'short',
  }

  for (let i = 0; i < 6; i++) {
    let currentDate = new Date()
    currentDate.setMonth(today.getMonth() - i)
    let isoDate = currentDate.toISOString().substr(0, 7).split('-')
    monthYears.push(`${isoDate[1]}/${isoDate[0]}`)
    formatedMonthYears.push(new Date(`${isoDate[1]}/01/${isoDate[0]}`).toLocaleDateString('fr-FR', dateFormat))
  }

  formatedMonthYears.reverse()

  const { data: scoresData, loading: scoresLoading } = useQuery(SCORES, {
    variables: {
      usersId: members.map(member => member.id),
      monthYears: monthYears.reverse(),
    },
  })

  if (scoresLoading) {
    return null
  }

  const switchTab = item => setActiveItem(item)

  return (
    <>
      <Title>Statistiques d&apos;équipe</Title>

      <NavItems>
        <Li onClick={ () => switchTab('averageScore') }>
          <Anchor $isCurrent={ activeItem === 'averageScore' }>
            Moyennes
          </Anchor>
        </Li>
        <Li onClick={ () => switchTab('totalScore') }>
          <Anchor $isCurrent={ activeItem === 'totalScore' }>
            Score cumulé
          </Anchor>
        </Li>
        <Li onClick={ () => switchTab('badges') }>
          <Anchor isCurrent={ activeItem === 'badges' }>
            Badges
          </Anchor>
        </Li>
      </NavItems>

      { activeItem === 'averageScore' && <AverageScore formatedMonthYears={ formatedMonthYears }
        scores={ scoresData.aggregatedStatistics }
                                         /> }
      { activeItem === 'totalScore' && <TotalScore formatedMonthYears={ formatedMonthYears }
        scores={ scoresData.aggregatedStatistics }
                                       /> }
      { activeItem === 'badges' && <Badges members={ members } /> }
    </>
  )
}

Statistics.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
}

const Title = styled.h3`
  margin: 2rem 0 1rem;
  font-family: 'Brother 1816';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  color: ${colors.lightGreen};
`

const NavItems = styled.ul`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  padding: 0;
  margin: 0;

  @media (width <= 680px) {
    && {
      li a {
        padding: 0;
        margin-right: 1rem;
      }
    }
  }
`

const Li = styled.li`
  list-style-type: none;
`

const Anchor = styled(Button)`
  && {
    padding-left: 0;
    margin-right: 1.9rem;
    ${({ $isCurrent }) => !$isCurrent && 'opacity: 0.5'};
    font-family: 'Brother 1816';
    font-style: normal;
    font-weight: 500;
    color: ${colors.midnightBlue};
  }
`

export default Statistics
