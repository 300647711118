import { colors } from 'theme'
import { CookieConsentContext } from 'context/CookieConsent'
import React, { useContext, useMemo } from 'react'
import CookieConsent from 'react-cookie-consent'
import styled from 'styled-components'

const CookieBanner = () => {
  const {
    isVisible,
    onAccept,
    onDecline,
  } = useContext(CookieConsentContext)

  const visible = useMemo(() => {
    return isVisible ? 'show' : 'hidden'
  }, [isVisible])

  return (
    <CookieConsent
      buttonStyle={ ButtonStyle }
      buttonText="J'accepte tout"
      cookieName='cookie-consent'
      declineButtonStyle={ ButtonStyle }
      declineButtonText="Je refuse tout"
      enableDeclineButton
      location='bottom'
      style={ {
        backgroundColor: colors.darkBlue,
      } }
      visible={ visible }
      onAccept={ onAccept }
      onDecline={ onDecline }
    >
      <P>
        Notre site internet utilise des cookies nécessaires à son bon fonctionnement.
      </P>
      <P>
        Ces cookies ne sont pas soumis à votre consentement et vous ne pouvez pas vous opposer à leur utilisation.
      </P>
      <P>
        Pour en savoir plus ou exercer votre droit d'opposition, consultez notre
        {' '}
        <b><a href='/cookies-policy'>politique de gestion des cookies</a></b>
        .
      </P>
    </CookieConsent>
  )
}

const P = styled.p`
  font-family: 'Brother 1816';
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.875rem;
  color: '#fff';

  @media (width <= 1050px) {
    font-size: 0.9rem;
  }

  @media (width <= 700px) {
    font-size: 0.8rem;
  }
`

const ButtonStyle = {
  margin: '15px',
  padding: '1rem 3rem',
  fontFamily: 'Montserrat',
  fontSize: '0.8em',
  borderRadius: '4px',
  boxShadow: 'rgba(0,0,0,0.35) 0 2px 5px 0',
  backgroundImage:
    'linear-gradient(to bottom right,rgb(35,188,186),rgb(0,229,166))',
  color: '#fff',
  fontWeight: '800',
  alignSelf: 'center',
}

export default CookieBanner
