import React, { useContext, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { USER } from 'graphql/queries/user'
import { useQuery } from '@apollo/client'
import { AuthHub } from 'components/auth'
import Modal from 'components/Modal'
import { ModalContext } from 'context/Modal'
import { MeQuery } from '@/types/graphql'
import { LANDING_PATH, REGISTER_PATH } from 'components/Router'
import AuthLayout from 'components/layouts/AuthLayout'

const Auth = () => {
  const userQuery = useQuery<MeQuery>(USER)
  const navigate = useNavigate()
  const location = useLocation()
  const { invitationToken } = useParams()
  const { actions } = useContext(ModalContext)
  const { closeModal, setModal } = actions

  useEffect(() => {
    if (!userQuery.loading && userQuery.data?.me != null && !invitationToken) {
      navigate(LANDING_PATH)
    }

    if (userQuery.data?.me != null && location.pathname === `${REGISTER_PATH}/${invitationToken}`) {
      setModal({
        title: 'Avertissement !',
        details: `Vous essayez d'inscrire un nouveau compte utilisateur à partir d'une invitation, alors que vous êtes actuellement connecté(e) en tant que ${userQuery.data.me.firstname} ${userQuery.data.me.lastname.toUpperCase()}.
          Merci de vous déconnecter, avant de réutiliser ce lien d'invitation.
        `,
        cancel: {
          text: 'Continuer',
          handleClick: () => {
            closeModal()
            navigate(LANDING_PATH)
          },
        },
      })
    }
  }, [location.pathname, invitationToken, userQuery, closeModal, setModal, navigate])

  if (userQuery.loading || (userQuery.data?.me != null && !invitationToken)) {
    return null
  }

  return (
    <AuthLayout>
      <Modal />
      <AuthHub />
    </AuthLayout>
  )
}

export default Auth
