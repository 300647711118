import React, { useEffect } from 'react'
import { useNavigate, useLocation, Route, Routes } from 'react-router-dom'
import { rgba } from 'polished'
import styled from 'styled-components'
import Profile from 'pages/me/Profile'
import ChangePassword from 'pages/me/ChangePassword'
import SubscriptionManagement from 'pages/me/SubscriptionManagement'
import UpgradeSubscription from 'pages/me/UpgradeSubscription'
import MyTeam from 'pages/me/MyTeam'
import Modal from 'components/Modal'
import SlimHeader from 'components/SlimHeader'
import ContactFooter from 'components/ContactFooter'
import Footer from 'components/Footer'
import {
  HeaderNav,
  AvatarEditor,
  MyStats,
} from 'components/me'
import { SUBSCRIPTION } from 'graphql/queries/user'
import { colors } from 'theme'
import DrawerProvider from 'context/Drawer'
import { SubscriptionDrawer } from 'components/landing'
import { useQuery } from '@apollo/client'

const Me = () => {
  const userQuery = useQuery(SUBSCRIPTION)
  const navigate = useNavigate()
  const location = useLocation()

  const meId = userQuery?.data?.me?.id
  const subOwnerId = userQuery?.data?.me?.subscription?.user?.id
  const hasTeamMenu = meId !== null && meId === subOwnerId

  useEffect(() => {
    if (!userQuery.loading) {

      // me being undefined means we are not logged
      if (userQuery.data?.me == null) {
        navigate('/')

        return
      }

      if (
        userQuery.data.me.subscription == null
        && location.pathname === '/me/subscription'
      ) {
        navigate('/me')

        return
      }
    }
  })

  if (userQuery.loading || userQuery.data?.me == null) {
    return null
  }

  return (
    <>
      <Modal />
      <Wrapper>
        <DrawerProvider>
          <SlimHeader>
            <HeaderNav />
          </SlimHeader>
          <SubRouteWrapper>
            <AvatarPart>
              <AvatarEditor />
              <MyStats />
            </AvatarPart>
            <SubRoute>
              <Routes>
                <Route
                  element={ <Profile /> }
                  path='/'
                />
                <Route
                  element={ <Profile /> }
                  path='/profile'
                />
                <Route
                  element={ <ChangePassword /> }
                  path="/change-password"
                />
                { userQuery.data.me.subscription &&
                  <>
                    <Route
                      element={ <SubscriptionManagement /> }
                      path="/subscription"
                    />
                    <Route
                      element={ <UpgradeSubscription /> }
                      path="/upgrade"
                    />
                    { hasTeamMenu &&
                      <Route
                        element={ <MyTeam /> }
                        path="/my-team"
                      />
                    }
                  </>
                }
              </Routes>
            </SubRoute>
          </SubRouteWrapper>
          <ContactFooter />
          <Footer />
          <SubscriptionDrawer />
        </DrawerProvider>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const SubRouteWrapper = styled.section`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-self: center;
  width: 60%;
  min-height: 70vh;
  margin-bottom: 5rem;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 3px 24px 0 rgb(0 0 0 / 12%);

  @media (width <= 1300px) {
    width: 100%;
  }
`

const AvatarPart = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 10rem;
  padding: 2.6875rem 3.125rem 0;
  text-align: center;
  background-image:
    linear-gradient(167deg, ${rgba(colors.seaGreen, 0.3)}, ${rgba(colors.lightGreen, 0.3)}),
    url(${require('assets/images/subscription-management/avatar-background.jpg')});

  @media (width <= 1050px) {
    padding: 2.6875rem 0 0;
  }

  @media (width <= 680px) {
    width: 100%;
  }
`

const SubRoute = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
  padding: 2.0625rem 3.75rem 3.75rem 5rem;

  @media (width <= 570px) {
    padding: 0.5rem 1.5rem 1.5rem;
  }
`

export default Me
