import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { ProfileForm, PharmacyForm } from 'components/me/profile'
import { SUBSCRIPTION } from 'graphql/queries/user'
import { checkOwner } from 'utils/subscription'
import { colors } from 'theme'
import DataCollectionInfo from 'components/atoms/DataCollectionInfo'

const Profile = () => {
  const userQuery = useQuery(SUBSCRIPTION)

  if (userQuery.loading || !userQuery.data?.me) {
    return null
  }

  const { me } = userQuery.data
  let isOwner = false
  let isTeam = false
  if (me.subscription !== null) {
    const { user } = me.subscription
    isOwner = checkOwner(me.id, user.id)
    isTeam = me.subscription.plan.metadata.threshold > 1
  }

  return (
    <>
      <DataCollectionInfo />
      <Title>
        à propos de vous
      </Title>
      <ProfileForm />
      { isOwner && isTeam &&
        <>
          <Title>
            votre pharmacie
          </Title>
          <PharmacyForm />
        </>
      }
    </>
  )
}

const Title = styled.h3`
  margin: 2rem 0 1rem;
  font-family: 'Brother 1816';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  color: ${colors.lightGreen};
`

export default Profile
