import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CheckBox } from 'components/atoms'
import { colors } from 'theme'

const Aknowledgement = ({
  isTeam,
  error,
  aknowledged,
  onChange,
}) =>
  <Wrapper>
    <CheckBox
      checked={ aknowledged }
      error={ error }
      id="aknowledged"
      label="aknowledged"
      name="aknowledged"
      onChange={ onChange }
    />
    <Disclaimer htmlFor="aknowledged">
      {'En vous inscrivant, vous acceptez nos '}
      <a href="/terms-of-use">
        {'conditions générales d\'utilisation'}
      </a>
      {' et nos '}
      <a href="/terms-of-use">
        { isTeam ? 'conditions générales de vente pharmacie' : 'conditions générales de vente' }
      </a>
      {', ainsi que notre '}
      <a href="/privacy-policy">
        politique de protection des données personnelles.
      </a>
    </Disclaimer>
  </Wrapper>

Aknowledgement.propTypes = {
  aknowledged: PropTypes.bool.isRequired,
  error: PropTypes.string,
  isTeam: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

Aknowledgement.defaultProps = {
  isTeam: false,
}

Aknowledgement.defaultProps = {
  error: null,
}

const Wrapper = styled.div`
  display: inline;
`

const Disclaimer = styled.label`
  all: initial;
  margin-left: 0.4375rem;
  font-family: 'Brother 1816';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
  color: ${colors.midnightBlue};
  user-select: none;
`

export default Aknowledgement
