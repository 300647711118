import React, { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'

type Props = {
  error?: string | null;
  labelId?: string;
  label: string;
  name: string;
  isRequired?: boolean;
}

const FormElement: FC<PropsWithChildren<Props>> = ({
  name,
  label,
  labelId,
  error,
  isRequired = false,
  children,
}) => (
  <>
    <Labels>
      <label
        htmlFor={ name }
        id={ labelId }
      >
        {label}
        {' '}
        { isRequired && '*' }
      </label>
      <HelpText
        $isError={ typeof error === 'string' }
        role="alert"
      >
        {error}
      </HelpText>
    </Labels>
    { children }
  </>
)

const Labels = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
`

type HelpTextProps = {
  $isError: boolean;
}

const HelpText = styled.span<HelpTextProps>`
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  color: ${colors.pink};
  opacity: ${({ $isError }) => $isError ? 1 : 0};
  transition: opacity 300ms ease-in-out;
`

export default FormElement
