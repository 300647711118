import client from 'apolloClient'
import CookieBanner from 'components/CookieBanner'
import Cookies from 'js-cookie'
import { FC, PropsWithChildren, createContext, useCallback, useEffect, useState } from 'react'
import { router } from 'components/Router'
import { toast } from 'react-toastify'

type CookieConsentContextType = {
    isVisible: boolean;
    hasConsent: boolean;
    show: () => void;
    hide: () => void;
    onAccept: () => void;
    onDecline: () => void;
};

export const CookieConsentContext = createContext<CookieConsentContextType>({
  isVisible: false,
  hasConsent: false,
  hide: () => {},
  show: () => {},
  onAccept: () => {},
  onDecline: () => {},
})

const CookieConsentProvider: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    const cookieConsent = Cookies.get('cookie-consent')

    setIsVisible(cookieConsent !== 'true')
  }, [])

  const [isVisible, setIsVisible] = useState(false)
  const hasConsent = Cookies.get('cookie-consent') === 'true'

  const toggle = useCallback(() => {
    setIsVisible(!isVisible)
  }, [isVisible])

  const show = () => setIsVisible(true)
  const hide = () => setIsVisible(false)

  const loadStripe = () => {
    const script = document.createElement('script')
    script.id = 'stripe-js'
    script.src = 'https://js.stripe.com/v3/'
    script.async = true

    document.head.appendChild(script)
  }

  const unloadStripe = () => {
    const script = document.getElementById('stripe-js')

    if (script) {
      script.remove()
    }
  }

  const removeAllCookies = async () => {
    const authToken = Cookies.get('authToken')

    for (const cookie in Cookies.get()) {
      document.cookie = `${cookie}=; expires=${new Date().toUTCString()}; path=/; domain=${window.location.hostname};`
    }

    for (const cookie in Cookies.get()) {
      Cookies.remove(cookie)
    }

    if (authToken != null) {
      toast.error('Vous avez été déconnecté car vous avez refusé les cookies. Votre cookie d\'authentification a donc été supprimé.')

      await client.resetStore()

      router.navigate('/login')
    }
  }

  const onAccept = useCallback(() => {
    toggle()
    loadStripe()
  }, [toggle])

  const onDecline = useCallback(() => {
    toggle()
    removeAllCookies()
    unloadStripe()
  }, [toggle])

  return (
    <CookieConsentContext.Provider value={ {
            isVisible,
            hasConsent,
            onAccept,
            onDecline,
            show,
            hide,
        } }
    >
      {children}
      <CookieBanner />
    </CookieConsentContext.Provider>
  )
}

export default CookieConsentProvider
