import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import { FC, PropsWithChildren } from 'react'

const StripeProvider: FC<PropsWithChildren> = ({ children }) => {
  if (process.env.REACT_APP_STRIPE_KEY == null) {
    throw new Error('REACT_APP_STRIPE_KEY is not defined')
  }

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
  const stripeOptions = {
    locale: 'fr',
    mode: 'setup',
    cur: 'eur',
    paymentMethodTypes: ['card', 'sepa_debit'],
    paymentMethodCreation: 'manual',
  } as StripeElementsOptions

  return (
    <Elements options={ stripeOptions }
      stripe={ stripePromise }
    >
      {children}
    </Elements>
  )
}

export default StripeProvider
