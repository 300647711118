import React, { useState, useEffect, useCallback } from 'react'
import { gql } from '@apollo/client'
import { logError } from 'logService'
import ProfileForm from './ProfileForm'
import { toast } from 'react-toastify'
import { isEmail } from 'utils/regex'
import { UPDATE_MY_PROFILE } from 'graphql/mutations/user'
import { mandatoryField, invalidField } from 'constants/form'
import { DEFAULT_COUNTRY } from 'constants/countries'
import { useQuery, useMutation } from '@apollo/client'

const Container = () => {
  const userQuery = useQuery(USER)
  const [updateMyProfile] = useMutation(UPDATE_MY_PROFILE)

  const [initialLoad, setInitialLoad] = useState(true)
  const [form, setForm] = useState({
    lastname: '',
    firstname: '',
    username: '',
    email: '',
    address: '',
    address2: '',
    city: '',
    zipCode: '',
    job: '',
    country: DEFAULT_COUNTRY,
  })
  const [errors, setErrors] = useState({})
  const [editionMode, setEditionMode] = useState(false)

  const fillForm = useCallback(() => {
    const { me } = userQuery?.data ?? {}

    if (me != null) {
      setForm({
        username: me.username ?? '',
        lastname: me.lastname ?? '',
        firstname: me.firstname ?? '',
        email: me.email ?? '',
        job: me.job ?? '',
        address: me.address.address ?? '',
        address2: me.address.address2 ?? '',
        city: me.address.city ?? '',
        zipCode: me.address.zipCode ?? '',
        country: me.address.country ?? DEFAULT_COUNTRY,
      })
    }

    setInitialLoad(false)
  }, [form, userQuery])

  useEffect(() => {
    if (!initialLoad) {
      return
    }

    if (undefined !== userQuery?.data?.me) {
      fillForm()
    }
  }, [initialLoad, userQuery, form, fillForm])

  const handleChange = (value, name) => {
    setEditionMode(true)
    let updatedErrors = { ...errors }

    updateFormValue(value, name)

    setErrors({
      ...updatedErrors,
      [name]: null,
    })
  }

  const updateFormValue = (value, name) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = async (e, formData) => {
    e.preventDefault()

    const {
      username,
      lastname,
      firstname,
      email,
      address,
      address2,
      city,
      zipCode,
      country,
      job,
    } = formData

    const errors = {}

    if (firstname.length === 0) {
      errors.firstname = mandatoryField
    }

    if (lastname.length === 0) {
      errors.lastname = mandatoryField
    }

    if (username.length === 0) {
      errors.username = mandatoryField
    }

    if (!isEmail.test(email)) {
      errors.email = invalidField
    }

    if (address.length === 0) {
      errors.address = mandatoryField
    }

    if (city.length === 0) {
      errors.city = mandatoryField
    }

    if (zipCode.length === 0) {
      errors.zipCode = mandatoryField
    }

    if (country.length === 0) {
      errors.country = mandatoryField
    }

    if (job.length === 0) {
      errors.job = mandatoryField
    }

    setErrors(errors)
    const hasError = Object.values(errors).some(error => typeof error === 'string')

    if (!hasError) {
      try {
        await updateMyProfile({
          variables: {
            user: {
              username,
              lastname,
              firstname,
              email,
              job: job,
              address: {
                address,
                address2,
                city,
                zipCode,
                country: country,
              },
            },
          },
          refetchQueries: [{
            query: USER,
          }],
          awaitRefetchQueries: true,
        })
        setEditionMode(false)
        document.activeElement.blur()
      } catch (e) {
        toast.error('Oups, une erreur est survenue', {
          position: 'top-center',
        })
        logError('GRAPHQL', 'updateMyProfile', e)
      }
    }
  }

  const handleCancel = () => {
    setEditionMode(false)
    setErrors({})
    fillForm()
  }

  return (
    <ProfileForm
      editionMode={ editionMode }
      errors={ errors }
      form={ form }
      onCancel={ handleCancel }
      onChange={ handleChange }
      onSubmit={ handleSubmit }
    />
  )
}

const USER = gql`
  query {
    me {
      id
      firstname
      lastname
      username
      email
      job
      address {
        address
        address2
        city
        zipCode
        country
      }
    }
  }
`

export default Container
