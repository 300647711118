import styled from 'styled-components'
import {
  Header,
  Footer,
} from 'components/auth'
import { FC, PropsWithChildren } from 'react'

const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Wrapper>
      <Main>
        <Header />
        { children }
        <Footer />
      </Main>
      <Placeholder />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  min-height: 100vh;
`

const Main = styled.div`
  display: flex;
  flex: 0.6;
  flex-direction: column;
  padding: 2rem 4rem;

  @media (width <= 700px) {
    padding: 0;
    margin: 2rem auto;
  }
`

const Placeholder = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  background-image: url(${require('assets/images/authhub/background.jpg')});
  background-repeat: no-repeat;

  @media (width <= 700px) {
    display: none;
  }
`

export default AuthLayout
