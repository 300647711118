import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import { Button, FormSelect } from 'components/atoms'
import DiscountBanner from 'components/landing/DiscountBanner'
import Form from 'components/atoms/Form'
import { format } from 'utils/price'
import { getAppliableDiscountLevel } from 'utils/plans'
import { isPharmUppUltra } from 'utils/origin'
import { useQuery } from '@apollo/client'
import { SUBSCRIPTION } from 'graphql/queries/user'
import { colors } from 'theme'

const DrawerBottom = ({
  form,
  plan,
  onChange,
  onSubmit,
  minLicencesNumber,
}) => {
  const {
    licencesCount,
    coupon,
  } = form

  const {
    interval,
    amount,
    metadata: {
      threshold,
      discount: planDiscount,
    },
  } = plan

  const licencesCountValue = threshold > 1 ? licencesCount.value : 1
  const hasMultipleLicences = threshold > 1
  const priceWithoutDiscount = (amount / 100) * licencesCountValue
  const hasDiscount = planDiscount !== null && planDiscount.levels != null
  const appliableDiscountLevel = hasDiscount
    ? getAppliableDiscountLevel(planDiscount.levels, licencesCountValue)
    : undefined
  const hasApplicableDiscountLevel = appliableDiscountLevel !== undefined

  let discount = 0

  if (coupon !== null) {
    discount = priceWithoutDiscount * coupon.percentOff
  }

  const subTotal = priceWithoutDiscount - discount
  const VAT = 0.2
  const tax = subTotal * VAT
  let totalTTC = subTotal + tax

  if (hasApplicableDiscountLevel) {
    totalTTC -= (subTotal + tax) * (appliableDiscountLevel.percentOff / 100)
  }

  const userQuery = useQuery(SUBSCRIPTION)
  const isLogged = Boolean(userQuery?.data?.me)
  const subscription = userQuery?.data?.me?.subscription

  const licencesCountOptions = useMemo(() => {
    const options = []

    for (let i = minLicencesNumber; i <= 100; i++) {
      options.push({
        label: `${i} licence${i > 1 ? 's' : ''}`
        , value: i })
    }

    return options
  }, [minLicencesNumber])

  const selectedLicenceCountOption = useMemo(() => {
    return licencesCountOptions.find(option => option.value === licencesCount.value)
  }, [licencesCount.value, licencesCountOptions])

  const hasAnotherSubscriptionType = useMemo(() =>
    (isLogged && subscription && subscription?.platform !== 'stripe')
  , [isLogged, subscription])

  return (
    <Wrapper>
      { hasDiscount &&
        <DiscountBanner
          description={ planDiscount.description }
          isOnDrawer={ true }
          title={ planDiscount.title }
        />
      }
      <Form
        withRequiredFieldInfo={ false }
        onSubmit={ onSubmit }
      >
        { hasMultipleLicences
          ?
            <FormSelect
              dropdownIndicatorStyle={ dropdownIndicatorStyle }
              inputStyle={ selectInputStyle }
              label="Choisissez le nombre de licences :"
              name="licencesCount"
              options={ licencesCountOptions }
              selectedOption={ selectedLicenceCountOption }
              onChange={ (value) => onChange({ value }, 'licencesCount') }
            />

          : null }
        <PriceCalculator>
          {
            !isPharmUppUltra()
              ?
                <>
                  <Row>
                    <span>{`Prix / ${interval === 'month' ? 'mois' : 'an'}`}</span>
                    <span>{`${format(amount / 100)}€ HT`}</span>
                  </Row>
                  <Separator />
                  <Row>
                    <span>Nb de licences</span>
                    <span>{licencesCountValue}</span>
                  </Row>
                  <Separator />
                  <Row>
                    <span>Sous-total</span>
                    <span>{`${format(subTotal)}€ HT`}</span>
                  </Row>
                  <Row>
                    <span>TVA</span>
                    <span>{`${format(tax)}€`}</span>
                  </Row>
                  { hasApplicableDiscountLevel &&
                    <>
                      <Separator />
                      <Row $bold>
                        <span>{appliableDiscountLevel.label}</span>
                        <span>{`-${appliableDiscountLevel.percentOff}%`}</span>
                      </Row>
                    </>
                  }
                  { coupon !== null
                    ?
                      <Row $discount>
                        <span>Réduction</span>
                        <span>{`-${format(discount)}€ HT`}</span>
                      </Row>
                    : null }
                  <Separator />
                </>
          : null
          }
          <Row $bold>
            <span>{`Total TTC/${plan.interval === 'year' ? 'an' : 'mois'}`}</span>
            <span>
              {`${format(totalTTC)}€`}
            </span>
          </Row>
          <Separator />
          { hasAnotherSubscriptionType && (
            <WarningMessage>
              Vous êtes déjà abonné à défimedoc depuis une autre plateforme. Pour souscrire à cette offre, veuillez annuler votre abonnement actuel.
            </WarningMessage>
          )}
          { isLogged
            ?
              <SubscribeButton
                disabled={ hasAnotherSubscriptionType }
                size="small"
                type="submit"
                variant="primary"
                onClick={ onSubmit }
              >
                {'Je m’abonne'}
              </SubscribeButton>
            :
              <SubscribeButton
                href="/login"
                size="small"
                variant="primary"
              >
                {'Me connecter'}
              </SubscribeButton>
          }
        </PriceCalculator>
      </Form>
    </Wrapper>
  )
}

DrawerBottom.propTypes = {
  form: PropTypes.shape({
    licencesCount: PropTypes.shape({
      value: PropTypes.number.isRequired,
    }).isRequired,
    coupon: PropTypes.shape({
      id: PropTypes.string.isRequired,
      percentOff: PropTypes.number.isRequired,
    }),
  }).isRequired,
  minLicencesNumber: PropTypes.number.isRequired,
  plan: PropTypes.shape({
    interval: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    metadata: PropTypes.shape({
      threshold: PropTypes.number.isRequired,
      discount: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.arrayOf(PropTypes.string).isRequired,
        levels: PropTypes.arrayOf(PropTypes.shape({
          label: PropTypes.string.isRequired,
          licencesCount: PropTypes.number.isRequired,
          percentOff: PropTypes.number.isRequired,
        })).isRequired,
      }),
    }).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

DrawerBottom.defaultProps = {
  coupon: null,
}

const Wrapper = styled.div`
  height: 100%;
  padding: 1.1875rem 2.625rem 1.1875rem 2.3125rem;
  background-color: ${colors.white};

  @media (width <= 550px) {
    padding-top: 0;
  }
`

const PriceCalculator = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1.25rem;
  margin-top: 1.875rem;
  background-color: ${colors.white};
  border: solid 1px ${rgba(colors.darkBlue, 0.08)};
  border-radius: 4px;
  box-shadow: 8px 2px 40px 0 rgb(0 0 0 / 18%);

  @media (width <= 550px) {
    margin-top: 0.5rem;
  }
`

const Row = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 2.5rem;

  & > span {
    font-family: 'Brother 1816';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: ${({ $bold }) => $bold ? '900' : 'normal' };
    color: ${colors.midnightBlue};
    ${({ $discount }) => $discount && `
      color: ${colors.lightGreen};
      font-weight: bold;
    `};

    &:first-of-type {
      width: 10rem;
    }
  }
`

const taupe = '#dae5f1'

const Separator = styled.div`
  height: 1px;
  background-color: ${taupe};
`

const SubscribeButton = styled(Button)`
  && {
    justify-content: center;
    margin-top: 1rem;

    &:disabled:hover {
      cursor: not-allowed;
    }
  }
`

const WarningMessage = styled.div`
  margin-top: 1rem;
  font-family: 'Brother 1816';
  font-size: small;
  font-style: normal;
  font-weight: bold;
  color: ${colors.red};
`

const selectInputStyle = {
  fontFamily: 'Brother 1816',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 600,
  color: colors.midnightBlue,
  border: `solid 1px ${colors.hanBlue}`,
  borderRadius: '5px',
  boxShadow: '0 4px 8px 0 rgb(0 0 0 / 18%)',
}

const dropdownIndicatorStyle = {
  backgroundColor: colors.hanBlue,
}

export default DrawerBottom
