import React from 'react'
import Markdown from 'components/atoms/Markdown'
import styled from 'styled-components'
import PolicyLayout from 'components/layouts/PolicyLayout'

const CookiePolicy = () =>
  <PolicyLayout>
    <Title>Politique de gestions des cookies</Title>
    <Markdown>
      {
        `
La politique de gestion des cookies est à intégrer au site internet de DEFIMEDOC ainsi que sur son application mobile. Elle doit être accessible depuis le bandeau cookies et depuis le _footer_, en lien permanent pour le site internet.

**POLITIQUE DE GESTION DES COOKIES**

Le bon fonctionnement du site internet [[https://www.defimedoc.fr/](https://www.defimedoc.fr/ "smartCard-inline") ] et de l’application mobile [defimedoc] repose sur l'utilisation de cookies ou autres traceurs. Cette page vous informe sur les cookies que nous utilisons, sur leur finalité et sur la manière de gérer vos préférences en matière de cookies.

_**Qu’est-ce qu’un cookie ou un traceur ?**_

Les cookies sont des fichiers textes, souvent cryptés, qui sont automatiquement stockés sur le terminal de l'utilisateur (ordinateur, tablette, téléphone, etc.) lorsqu'il visite un site web ou lorsqu’il installe ou consulte une application mobile. Ils sont notamment créés :

- Lorsque le navigateur de l'utilisateur charge un site web donné : le site envoie des informations au navigateur, qui crée alors un fichier texte. Chaque fois que l'utilisateur revient sur le même site, le navigateur récupère ce fichier et l'envoie au serveur du site web, ce qui lui permet de se souvenir de la visite de l'utilisateur à chaque fois qu'il revient sur le site.

- En ce qui concerne l’utilisation des applications mobiles, c’est l’application qui maîtrise directement les cookies et qui peut stocker et lire une information liée à la navigation en cours. Ce qui signifie que toutes les informations recueillies par le biais de ces cookies sont uniquement envoyées à cette application. Aucune autre application présente sur l’appareil de l’utilisateur (téléphone, tablette) ne peut accéder aux informations stockées.

Le cookie contient généralement le nom du serveur qui le dépose, un identifiant sous la forme d'un numéro unique et une date d'expiration.

_**Quels cookies utilisons-nous ?**_

Nous utilisons des cookies techniques, qui sont nécessaires au bon fonctionnement du site web et sont utilisés tout au long de votre navigation pour faciliter la navigation et exécuter certaines fonctions.

Un cookie technique peut, par exemple, être utilisé pour l’authentification auprès d’un service, pour mémoriser les réponses données dans un formulaire, le contenu d’un panier d’achat électronique ou encore les préférences de l’utilisateur concernant la langue ou la présentation d’un site web ou d’une application, lorsque de telles options sont disponibles.

Les caractéristiques des cookies techniques que nous utilisons sont détaillées dans le tableau ci-dessous :`
      }
    </Markdown>
    <Table cellSpacing={ 0 }>
      <tr>
        <th>Source</th>
        <th>Nom du cookie</th>
        <th>Finalité</th>
        <th>Durée de conservation des données</th>
      </tr>
      <tr>
        <td>Defimedoc</td>
        <td>basicAuth</td>
        <td>Authentification de l'utilisateur</td>
        <td>Le temps de que la requête de connexion soit processée</td>
      </tr>
      <tr>
        <td>Defimedoc</td>
        <td>authToken</td>
        <td>Authentification de l'utilisateur</td>
        <td>1 mois</td>
      </tr>
      <tr>
        <td>Defimedoc</td>
        <td>cookieConsent</td>
        <td>Savoir si l'utilisateur à accepter les cookies</td>
        <td>1 ans</td>
      </tr>
      <tr>
        <td>Stripe</td>
        <td>__stripe_sid</td>
        <td>Soutient au paiement</td>
        <td>30 minutes</td>
      </tr>
      <tr>
        <td>Stripe</td>
        <td>__stripe_mid</td>
        <td>Soutient au paiement</td>
        <td>30 minutes</td>
      </tr>
    </Table>
    <Markdown>
      {
        `
_**Comment mon consentement est-il obtenu ?**_

Les cookies techniques et certains cookies de mesure d'audience ne sont pas soumis à votre consentement. La loi nous autorise à placer des cookies sur votre terminal, il suffit de vous en informer.

Nous vous signalons toutefois que vous pouvez configurer votre navigateur pour accepter ou refuser ces cookies. Notez toutefois que cela peut affecter votre confort de navigation, voire vous empêcher d'accéder à certains services. La configuration de chaque navigateur est différente. Elle est décrite dans le menu d'aide de votre navigateur qui vous indiquera comment modifier vos choix en matière de cookies.

A titre d'exemple :

- Pour Internet Explorer™:
[http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies](http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies "smartCard-inline")

- Pour Safari™:
[https://support.apple.com/fr-fr/safari](https://support.apple.com/fr-fr/safari "smartCard-inline")


- Pour Chrome™ :
[http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647](http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647 "smartCard-inline")


- Pour Firefox™ :
[http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies](http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies "‌")


- Pour Opera™:
[http://help.opera.com/Windows/10.20/fr/cookies.html](http://help.opera.com/Windows/10.20/fr/cookies.html "smartCard-inline") .


Pour en savoir plus, nous vous invitons à visiter le site de la Commission nationale de l'informatique et des libertés.

Le placement ou la lecture d'autres cookies est soumis à votre consentement préalable. Lors de votre première visite sur notre site, il vous sera demandé d'accepter l'utilisation de certains cookies par le biais d'un bandeau d'information dédié. Ce n'est qu'en cochant la case "accepter" que vous autorisez le dépôt de cookies sur votre terminal. Si vous refusez ou si vous fermez le bandeau cookies sans faire de choix, cela équivaut à un refus de votre part : aucun cookie ne sera déposé ou lu sur votre terminal.

Vous pouvez changer d'avis à tout moment en cliquant sur le lien "Paramétrer les cookies" en bas de chaque page du site internet.`
      }
    </Markdown>
  </PolicyLayout>

const Title = styled.h1`
  font-family: 'Brother 1816';
  font-style: normal;
  font-weight: 900;
  text-align: center;
`

const Table = styled.table`
  font-family: 'Brother 1816';

  td {
    padding: 0.5rem;
    border: 1px solid black;
  }

  th {
    border: 1px solid black;
  }
`
export default CookiePolicy
