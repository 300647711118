import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, FormInput } from 'components/atoms'
import { colors } from 'theme'
import { InputType } from 'components/atoms/Input'

const LicenceInput = ({
  errors,
  form,
  sent,
  loading,
  onChange,
  onSubmit,
  onDelete,
  disableDelete,
  index,
}) => {
  const {
    lastname,
    firstname,
    email,
  } = form

  return (
    <Wrapper>
      <Title>
        { `Licence N° ${index + 1}` }
      </Title>
      <Form
        onSubmit={ onSubmit }
      >
        <Row>
          <InputWrapper>
            <FormInput
              disabled={ sent }
              error={ errors.lastname }
              isRequired
              label="Nom"
              name='lastname'
              type={ InputType.TEXT }
              value={ lastname }
              onChangeText={ onChange }
            />
          </InputWrapper>
          <InputWrapper>
            <FormInput
              disabled={ sent }
              error={ errors.firstname }
              isRequired
              label="Prénom"
              name='firstname'
              type={ InputType.TEXT }
              value={ firstname }
              onChangeText={ onChange }
            />
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <FormInput
              disabled={ sent }
              error={ errors.email }
              isRequired
              label="Email"
              name='email'
              type={ InputType.EMAIL }
              value={ email }
              onChangeText={ onChange }
            />
          </InputWrapper>
        </Row>
        <ValidateButton
          disabled={ sent || loading }
          size="medium"
          type="submit"
          variant="primary"
        >
          {sent ? 'Envoyé' : 'Envoyer'}
        </ValidateButton>
      </Form>
      { !disableDelete && sent ?
        <DeleteButton
          size="medium"
          type="submit"
          variant="primary"
          onClick={ onDelete }
        >
          Supprimer le bénéficiaire
        </DeleteButton>
        : null }
    </Wrapper>
  )
}

LicenceInput.propTypes = {
  disableDelete: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  form: PropTypes.shape({
    lastname: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  sent: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

LicenceInput.defaultProps = {
  disableDelete: false,
}

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-width: 0;
`

const Title = styled.h4`
  box-sizing: border-box;
  min-width: 0;
  margin-bottom: 0.8rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  color: ${colors.midnightBlue};
`

const Form = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  min-width: 0;
  margin: 0 1.5rem 1rem 0;
`

const ValidateButton = styled(Button)`
  && {
    align-self: baseline;
    padding: 0.83rem;
    margin-right: 1.25rem;
    background-color: ${colors.lightGreen};
    background-image: none;
    border-radius: 0;
    box-shadow: none;
  }
`

const DeleteButton = styled(Button)`
  && {
    position: absolute;
    padding: 0.83rem;
    margin: 13.8rem 7rem;
    color: ${colors.lightGreen};
    background-image: none;
    border: 1px solid ${colors.lightGreen};
    box-shadow: none;
  }
`

const Row = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  min-width: 0;
  margin-right: 1.5rem;

  & > div:last-child {
    margin-right: 0;
  }
`

const InputWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
  margin-right: 1.3125rem;
`

export default LicenceInput
