import React from 'react'
import { useQuery } from '@apollo/client'
import TeamSummary from 'components/my-team/TeamSummary'
import Statistics from 'components/my-team/Statistics'
import { SUBSCRIPTION } from 'graphql/queries/user'

const MyTeam = () => {
  const { data: teamSummaryData, loading } = useQuery(SUBSCRIPTION)

  if (loading) {
    return null
  }

  const sortedTeamMembers = [...teamSummaryData.me.subscription.teamMembers].sort(function(a, b) {
    if (a.monthlyStatistic.lastQuizDate === null) {
      return 1
    }
    if (b.monthlyStatistic.lastQuizDate === null) {
      return -1
    }
    let dateA = new Date(Date.parse(a.monthlyStatistic.lastQuizDate))
    let dateB = new Date(Date.parse(b.monthlyStatistic.lastQuizDate))

    return dateB - dateA
  })

  return (
    teamSummaryData.me.id === teamSummaryData.me.subscription.user.id &&
      <>
        <TeamSummary
          subscription={ teamSummaryData.me.subscription }
        />
        <Statistics members={ sortedTeamMembers } />
      </>
  )
}

export default MyTeam
