import React from 'react'
import styled from 'styled-components'
import {
  CurrentSubscription,
  Licences,
  PaymentMethodManagement,
  BillingAddressForm,
  Invoices,
} from 'components/me/subscriptionManagement'
import { SUBSCRIPTION } from 'graphql/queries/user'
import { checkOwner } from 'utils/subscription'
import { useQuery } from '@apollo/client'
import StripeProvider from 'context/StripeProvider'

const SubscriptionManagement = () => {
  const userQuery = useQuery(SUBSCRIPTION)

  if (userQuery.loading || !userQuery.data?.me) {
    return null
  }

  const { me } = userQuery.data
  const { quantity, user, plan } = me.subscription
  const isOwner = checkOwner(me.id, user.id)

  return (
    <>
      <CurrentSubscription isOwner={ isOwner } />
      { isOwner && me?.subscription?.platform === 'stripe' ?
        <>
          <Separator />
          { quantity !== 1 ?
            <>
              <p className="text-content">
                {`Vous souhaitez ${ plan.interval === 'year' && plan.isTeam === true ? 'augmenter' : 'augmenter ou diminuer' } votre nombre de licences ? Contactez-nous sur `}
                <a href="mailto:contact@defimedoc.fr">contact@defimedoc.fr</a>
              </p>
              <Licences />
              <Separator />
            </>
            : null
          }
          <StripeProvider>
            <PaymentMethodManagement />
          </StripeProvider>
          <Separator />
          { isOwner && plan.metadata.threshold > 1 ?
            <>
              <BillingAddressForm />
              <Separator />
            </>
            : null
          }
          <Invoices />
        </>
        : null
      }
    </>
  )
}

const Separator = styled.hr`
  width: 100%;
  margin: 1.5rem 0;
  border: solid 0.5px #a2bfdd;
`
export default SubscriptionManagement
