import React from 'react'
import {
  Hero,
  Main,
} from 'components/dpc/Presentation'
import DpcLayout from 'components/layouts/DpcLayout'

const Presentation = () => {
  return (
    <DpcLayout headerWithBackground={ false }>
      <Hero />
      <Main />
    </DpcLayout>
  )
}

export default Presentation
